import { createSlice } from "@reduxjs/toolkit";
import { fetchItems } from "../services/items.service";


const reducerSlice = createSlice({
  name: "store",
  initialState: {
    items: [],
    itemsDisplay: [],
    layoutCollapse: false,
    user: null,
    accessToken: "",
    loading: true,
    lineItems: [],
    loadAgain: false,
    searchText: "",
  },
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
      state.loading = false;
      state.loadAgain = false
    },
    setItemsDisplay: (state, action) => {
      state.itemsDisplay = action.payload;
    },
    setLayoutCollapse: (state, action) => {
      state.layoutCollapse = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setLineItems: (state, action) => {
      state.lineItems = action.payload
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload
    },
    setLoadAgain: (state, action) => {
      state.loadAgain = action.payload;
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload
    }
  },
});

export default reducerSlice.reducer;

//action
export const {
  setItems,
  setItemsDisplay,
  setLoading,
  setUser,
  setLineItems,
  setAccessToken,
  setLoadAgain,
  setLayoutCollapse,
  setSearchText
} = reducerSlice.actions;

export const setItemsStore = () => async (dispatch) => {
    try {
      const items = await fetchItems();
      if(items) {
        const handleItems = items.data.payload.items.map(item => {
          return {
            ...item,
            quantity: 0,
          }
        })
        dispatch(setAccessToken(items.data.payload.accessToken))
        return dispatch(setItems(handleItems))
      }
      return null
    } catch(err) {
        return console.error(err)
    }
}
