import { Table, Input, Button, Form } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setItems, setItemsDisplay, setLineItems, setSearchText } from "../../store/store";
import _, { update } from "lodash";
import ItemImage from "./Image";

const TableField = ({ items, itemsDisplay }) => {
  const dispatch = useDispatch();
  const { lineItems, accessToken, searchText } = useSelector((store) => store.store);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })
  
  // filter on tables
  const typeOfFilter = (type) => {
    const nameArray = [];
    const skuArray = [];
    // eslint-disable-next-line default-case
    switch (type) {
      case "name":
        itemsDisplay.forEach((o) =>
          nameArray.push({
            text: o.name,
            value: o.name,
          })
        );
        return Array.from(new Set(nameArray));
      case "sku":
        itemsDisplay.forEach((o) =>
          skuArray.push({
            text: o.sku,
            value: o.sku,
          })
        );
        return Array.from(new Set(skuArray));
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image_document_id",
      render: (a) => {
        return <ItemImage documentID={a} accessToken={accessToken}/>;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      filters: typeOfFilter("sku"),
      onFilter: (value, record) => record.sku.indexOf(value) === 0,
      sorter: (a, b) => a.sku.length - b.sku.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      filters: typeOfFilter("name"),
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Price",
      dataIndex: "rate",
      filters: typeOfFilter("rate"),
      render: (value) => value && value.toFixed(2),
      onFilter: (value, record) => record.rate.indexOf(value) === 0,
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "Quantity on hand",
      key: "quantity",
      dataIndex: "stock_on_hand",
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        return (
          <>
            {record.quantity && record.quantity > 0 ? (
              <Button
                type="primary"
                danger
                onClick={() => removeFromCart(record)}
              >
                Remove from Cart
              </Button>
            ) : (
              <div
                style={{
                  display: "flex",
                }}
              >
                <Form
                  onFinish={(values) => addToCart(values, record)}
                  layout="inline"
                >
                  <Form.Item
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your quantity!",
                      },
                    ]}
                  >
                    <Input min="1" required type="number"/>
                  </Form.Item>
                  <Button type="primary" htmlType="submit">
                    Add To Cart
                  </Button>
                </Form>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const addToCart = (values, record) => {
    const { quantity } = values;
    const item = _.omit(record, ["product_type"]);
    dispatch(setLineItems([...lineItems, { ...item, quantity }]));
    //update items
    const updateItems = items.map((ele) => {
      if (record.item_id === ele.item_id) {
        return {
          ...ele,
          quantity: quantity || 1,
        };
      }
      return ele;
    });
    //update items display
    const updateItemsDisplay = itemsDisplay.map((ele) => {
      if (record.item_id === ele.item_id) {
        return {
          ...ele,
          quantity: quantity || 1,
        };
      }
      return ele;
    });
    dispatch(setItemsDisplay(updateItemsDisplay))
    dispatch(setItems(updateItems));
  };

  const removeFromCart = (record) => {
    const item = _.omit(record, ["product_type"]);
    const arr = [...lineItems];
    lineItems.forEach((ele, index) => {
      if (ele.item_id === item.item_id) {
        arr.splice(index, 1);
      }
    });
    dispatch(setLineItems(arr));
    const updateItems = itemsDisplay.map((ele) => {
      if (record.item_id === ele.item_id) {
        return {
          ...ele,
          quantity: 0,
        };
      }
      return ele;
    });

    dispatch(setItems(updateItems));
  };

  // search item table
  const onToggleSearch = (e) => {
    dispatch(setSearchText(e.target.value))
    // setSearch(e.target.value.toLowerCase());
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination)
  };

  return (
    <div className="container tablist-desktop">
      <div className="sales_title table_title">
        <span>Catalogue</span>{" "}
        <Input placeholder="Search" onChange={onToggleSearch} value={searchText}/>
      </div>
      <div className="table-list">
        <Table
          columns={columns}
          rowKey={record => record.sku}
          dataSource={itemsDisplay}
          onChange={handleTableChange}
          size="large"
          pagination={pagination}
        />
      </div>
    </div>
  );
};

export default TableField;