import React from "react";
import MenuDesktop from "./menu";
import MenuTablet from "./menu-resonsive";

const Menu = () => {
  return (
    <>
      <MenuDesktop />
      <MenuTablet />
    </>
  );
};

export default Menu;
