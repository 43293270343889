import request from "../util/Api";
import * as Urls from "../constants/Config";

export const fetchItems = () => {
  return request({
    url: `${Urls.API_URL}zoho_inventory/zoho_inventory_fetch_items`,
    method: "GET",
  });
};

export const fetchItemImage = (documentID, accessToken) => {
    return request({
        url: `${Urls.API_URL}zoho_inventory/zoho_inventory_item_image?documentID=${documentID}&accessToken=${accessToken}`,
        method: "GET", 
        responseType: "blob"
    })
}
