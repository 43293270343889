import React, { useEffect } from "react";
import Menu from "../components/Menu/index";
import DashboardRoutes from "./DashboardRoutes";
import { useDispatch, useSelector } from "react-redux";
import { setItemsStore } from "../store/store";

const Pages = () => {
  const dispatch = useDispatch();
  const { layoutCollapse } = useSelector((store) => store.store);
  useEffect(() => {
    dispatch(setItemsStore());
  }, [dispatch]);

  return (
    <div className="main">
      <div className="menu">
        <Menu />
      </div>
      <div className="main-body" style={
        !layoutCollapse ? {marginLeft: "300px"} : {marginLeft: "219.7px"}
      }>
        <DashboardRoutes />
      </div>
    </div>
  );
};

export default Pages;
