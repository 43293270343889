import {
  Form,
  Input,
  InputNumber,
  Upload,
  Button,
  PageHeader,
  AutoComplete,
  Table,
  Spin,
  notification,
  List,
  Radio
} from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import moment from "moment";
import Utils from "../../util/Functions";
import _ from "lodash";
import { createSaleOrder } from "../../services/saleOrder.service";
import { setLineItems, setItems, setLoading as setLoadingStore, setLoadAgain } from "../../store/store";
import TableResponsive from "../Table/Table-resonsive";

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

const SaleOrderForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [uploadLoading, setUploadLoading] = useState(false);
  const { items, lineItems } = useSelector((store) => store.store);
  const [options, setOptions] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [delivery, setDelivery] = useState("No")
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"))

  const onFinish = async (values) => {
    setLoading(true);
    let notes = `Contact: ${values.fieldName}\nDelivery: ${values.delivery}` 

    if(delivery === "Yes") {
    notes = notes + `\nReceiver Name: ${values.receiverName}\nReceiver Phone: ${values.receiverPhone}\nDelivery Address: ${values.deliveryAddress}`
    }

    if(values && values.nodes) {
      notes = notes + `\nNotes: ${values.notes}`
    }

    const data = {
      customer_id: user && user.data && user.data.customer.id,
      notes: notes.trim(),
      reference_number: values.purchaseOrderNumber,
      date: moment().format("YYYY-MM-DD"),
      line_items: lineItems,
    };

    const submitData = _.omit(data, ["lineItem", "customerName"]);
    createSaleOrder(submitData, fileList[0], user.data.email, user.data.name)
      .then((res) => {
        notification.success({
          message: `Sale Order Created Success`,
          placement: "topLeft",
        });
        dispatch(setLineItems([]))
        dispatch(setLoadAgain(true))
        dispatch(setLoadingStore(true))
        history.push("/dashboard/items")
      })
      .catch((err) => {
        notification.error({
          message: `Some thing wrong`,
          placement: "topLeft",
        });
      })
      .finally(() => setLoading(false));
  };

  const [form] = Form.useForm();

  const onHandleChangeInputLineItem = (prop, e, record) => {
    const id = record.item_id;
    const updateItems = lineItems.map((item) => {
      if (item.item_id === id) {
        return {
          ...item,
          [prop]: e,
        };
      }
      return item;
    });

    dispatch(setLineItems(updateItems));
  };

  const onSearch = (searchText) => {
    const arr =
      searchText.length === 0
        ? items
        : Utils.filterArrayByString(items, searchText);
    const handleItem = arr.map((item) => {
      return renderItem(item);
    });
    setOptions(handleItem);
  };

  const onChange = e => {
    setDelivery(e.target.value);
  };

  const handleSelectLineItems = (e, option) => {
    const item = _.omit(option.item, ["product_type"]);
    dispatch(setLineItems([...lineItems, item]));
  };

  const removeFromCart = (record) => {
    const item = _.omit(record, ["product_type"]);
    const arr = [...lineItems];
    lineItems.forEach((ele, index) => {
      if (ele.item_id === item.item_id) {
        arr.splice(index, 1);
      }
    });
    dispatch(setLineItems(arr));
    const updateItems = items.map((ele) => {
      if (record.item_id === ele.item_id) {
        return {
          ...ele,
          quantity: 0,
        };
      }
      return ele;
    });

    dispatch(setItems(updateItems));
  };

  const renderItem = (item) => ({
    item: item,
    value: item.item_name,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ margin: "0px" }}>{item.item_name}</p>
          <p style={{ margin: "0px" }}>SKU: {item.sku}</p>
          <p style={{ margin: "0px" }}>Rate: {item.rate}</p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ margin: "0px" }}>Stock On Hand</p>
          <p style={{ margin: "0px" }}>
            {Number(item.stock_on_hand).toFixed(2)}
          </p>
        </div>
      </div>
    ),
  });

  const lineItemColumns = [
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      onFilter: (value, record) => record.sku.indexOf(value) === 0,
      sorter: (a, b) => a.sku.length - b.sku.length,
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "QUANTITY",
      key: "quantity",
      render: (value, record) => (
        <>
          <InputNumber
            defaultValue="1"
            min={1}
            value={record.quantity}
            onChange={(e) => onHandleChangeInputLineItem("quantity", e, record)}
          />
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        return (
          <>
            <Button
              type="primary"
              danger
              onClick={() => removeFromCart(record)}
            >
              Remove from Cart
            </Button>
          </>
        );
      },
    },
  ];

  const props = {
    name: 'file',
    maxCount: 1,
    fileList,
    onRemove: file => {
        const index = fileList.indexOf(file);
        const list = [...fileList]
        list.splice(index, 1);
        setFileList([...list])
    },
    beforeUpload: file => {
        setFileList([file]);
    }
};

  useEffect(() => {
    if (items) {
      const handleItem = items.map((item) => {
        return renderItem(item);
      });
      setOptions(handleItem);
    }
  }, [items]);

  useEffect(() => {
    if (user && user.data) {
      form.setFields([
        {
          name: "fieldName",
          value: user.data.name
        },
        {
          name: "delivery",
          value: delivery
        }
      ])
    }
  }, [user]);

  return (
    <div>
      <PageHeader className="site-page-header" title="Shopping Cart" />
      <Spin spinning={loading} size="large">
        <Form
          form={form}
          layout="vertical"
          style={{ marginTop: "20px", padding: "0 30px" }}
          name="nest-messages"
          className="sale-order_form"
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Form.Item name="lineItem">
            <AutoComplete
              dropdownClassName="certain-category-search-dropdown"
              dropdownMatchSelectWidth={500}
              options={options}
              onSelect={(val, option) => handleSelectLineItems(val, option)}
              onSearch={onSearch}
            >
              <Input.Search size="large" placeholder="input here" />
            </AutoComplete>
            <Table
              className="tablist-desktop"
              columns={lineItemColumns}
              dataSource={lineItems}
              key="table"
              size="large"
              pagination={{ pageSize: 5 }}
            />
            {/* Table responsive */}
            <List
              className="tablist-respon"
              size="small"
              pagination={{
                onChange: (page) => {},
                pageSize: 5,
              }}
              dataSource={lineItems}
              renderItem={(item) => (
                <>
                  <List.Item key={item.item_id}>
                    <List.Item.Meta
                      title={<a href={item.href}>{item.name}</a>}
                      description={item.sku}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "10px 0",
                      }}
                    >
                      <span
                        style={{
                          marginRight: "10px",
                        }}
                      >
                        Quantity :{" "}
                      </span>
                      <InputNumber
                        defaultValue="1"
                        min={1}
                        value={item.quantity}
                        onChange={(e) =>
                          onHandleChangeInputLineItem("quantity", e, item)
                        }
                      />
                    </div>
                    <Button
                      type="primary"
                      danger
                      onClick={() => removeFromCart(item)}
                    >
                      Remove from Cart
                    </Button>
                  </List.Item>
                </>
              )}
            />
          </Form.Item>

          <Form.Item name="fieldName" label="Name">
            <Input disabled />
          </Form.Item>

          <Form.Item name="purchaseOrderNumber" label="Purchase Order Number">
            <Input />
          </Form.Item>

          <Form.Item name="delivery" label="Delivery">
            <Radio.Group onChange={onChange} value={delivery} defaultValue={"No"}>
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
          </Form.Item>

          {
            delivery === "Yes"
            ? <>
                <Form.Item name="receiverName" label="Receiver Name"  rules={[{ required: true, message: 'Missing Receiver Name' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="receiverPhone" label="Receiver Phone" rules={[{ required: true, message: 'Missing Receiver Phone' }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="deliveryAddress" label="Delivery Address" rules={[{ required: true, message: 'Missing Delivery Address' }]}>
                  <Input />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 10 }} name="packingSlip" label="Packing Slip">
                    <Upload {...props}>
                        <Button icon={<UploadOutlined />} loading={uploadLoading}>Click to Upload</Button>
                    </Upload>
                </Form.Item>
              </>
            : ""
          }

          <Form.Item name="notes" label="Notes">
            <Input.TextArea />
          </Form.Item>

          <Form.Item style={{ margin: "auto" }} className="form-btn_sale">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default SaleOrderForm;
