import React from "react";
import {
  PieChartOutlined,
  ShoppingCartOutlined,
  ReadOutlined
} from "@ant-design/icons";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";

const MenuShow = [
  {
    path: "/dashboard/board",
    auth: ["admin"],
    key: "1",
    description: "Sales Orders",
    icon: <PieChartOutlined />,
  },
  {
    path: "/dashboard/items",
    auth: ["admin", "user"],
    key: "2",
    description: "Catalogue",
    icon: <ReadOutlined />,
  },
  {
    path: "/dashboard/sale-order/create",
    auth: ["admin", "user"],
    key: "3",
    description: "Shopping Cart",
    icon: <ShoppingCartOutlined />,
  },
];

const MenuByRoles = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  if (!user || !user.data.role || user.data.role === "") {
    return [];
  }
  const role = user.data.role;
  const show = MenuShow.filter((menu) => menu.auth.includes(role));

  return (
    <>
      <Menu
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        theme="dark"
      >
        {show.map((subMenu) => {
          return (
            <Menu.Item
              key={subMenu.key}
              icon={subMenu.icon}
              onClick={() => history.push(subMenu.path)}
            >
              {subMenu.description}
            </Menu.Item>
          );
        })}
      </Menu>
    </>
  );
};

export default MenuByRoles;
