import React, { useState, useEffect } from "react";
import Board from "./OrderBoard";
import io from "socket.io-client";

const socket = io("https://api-inspect.herokuapp.com/");

const BoardList = () => {
  const statuses = ["Received", "Backorder", "Packed", "Shipped"];
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    socket.on("getData", (res) => {
      if (res.orders) {
        setOrders(res.orders);
      }
    });
  }, [socket]);

  return (
    <div className="container">
      <div className="sales_title">Sales Orders</div>
      <div className="board-list">
        {statuses.map((status) => (
          <Board key={status} status={status} orders={orders} />
        ))}
      </div>
    </div>
  );
};

export default BoardList;
