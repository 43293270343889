import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { loginService } from "../../services/login.service";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { setUser } from "../../store/store";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const onFinish = (values) => {
    setLoading(true);
    const { email, password } = values;
    loginService({
      email: email.toLowerCase(),
      password
    }).then((res) => {
      if (res && res.data) {
        if (res.data.payload.data.code === 200) {
          localStorage.setItem(
            "role",
            JSON.stringify(res.data.payload.data.email)
          );
          localStorage.setItem("user", JSON.stringify(res.data.payload));
          dispatch(setUser(res.data.payload))
          history.push("/dashboard/items");
        } else {
          message.error(res.data.payload.data.message);
        }
      }
    }).finally(() => setLoading(false));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    localStorage.removeItem("role");
    localStorage.removeItem("user");
  }, []);

  return (
      <div className="login">
        <Spin spinning={loading}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
          </Form.Item>

          <Form.Item
            label="Pin"
            name="password"
            rules={[{ required: true, message: "Please input your pin!" }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Pin"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
        </Spin>
      </div>
  );
};

export default Login;
