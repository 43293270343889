import { useState, useEffect } from "react";
import { Button } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import MenuByRoles from "./MenuByRoles";
import { useDispatch } from "react-redux";
import { setLayoutCollapse } from "../../store/store";

const MenuBar = () => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [user, setUser] = useState();
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    dispatch(setLayoutCollapse(!collapsed))
  };

  const logout = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("user");
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  return (
    <>
      {/* Desktop screen show */}
      <div
        className="menu-container"
        style={{ width: !collapsed ? 300 : "max-content" }}
      >
        <div className="collaspe-title">
          <h2>Portal</h2>
          <Button
            type="primary"
            onClick={toggleCollapsed}
            style={{ marginBottom: 16 }}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </div>
        {user && user.data && (
          <div className="infor-user">
            <h2>{user.data.customer && user.data.customer.name}</h2>
            <h3>{user.data.email}</h3>
          </div>
        )}
        <MenuByRoles/>
         <a href="/login" onClick={() => logout()}>
            <h2 className="logout-title"><LogoutOutlined/> Logout</h2>
        </a>
      </div>
    </>
  );
};

export default MenuBar;
