import Layouts from "./layouts/Layouts";
import "./styles/index.scss";
import "./App.css";
import "antd/dist/antd.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

const Pages = () => {
  return (
    <Provider store={store}>
    <div className="main">
      <Router>
        <Layouts />
      </Router>
    </div>
    </Provider>
  );
};

export default Pages;
