import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { setSearchText } from "../../store/store";

const BarCode = ({
    title,
    isModalVisible,
    handleCancel
}) => {
    const dispatch = useDispatch();
    const [scanResultWebCam, setScanResultWebCam] =  useState('');
   
    const handleScanWebCam = (err, result) => {
        if (result) {
            setScanResultWebCam(result.text)
            dispatch(setSearchText(result.text))
            handleCancel();
        }
    }

    return (
        <Modal
            title={title}
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
        >
           <>
                <BarcodeScannerComponent
                    style={{ width: '100%' }}
                    onUpdate={(err, result) => handleScanWebCam(err, result)}
                />
                <p>{scanResultWebCam}</p>
            </>
        </Modal>
    )
}

export default BarCode;