import Board from "../components/Board/OrderBoardList";
import Table from "../components/Table/";
import SaleOrderCreate from "../components/SaleOrderForm";

import {
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

const DashboardROutes = ({ match: { url } }) => {
  const user = JSON.parse(localStorage.getItem("user"))

  const RoutePath = [
    {
      path:`${url}/board`,
      component: (Board),
      auth: ["admin"]
    },
    {
      path:`${url}/items`,
      component: (Table),
      auth: ["admin", "user"]
    },
    {
      path:`${url}/sale-order/create`,
      component: (SaleOrderCreate),
      auth: ["admin", "user"]
    },
  ]

  const MenuShow = () => {
    if(!user || !user.data.role || user.data.role === "") {
        return []
    }

    const role = user.data.role;

    return RoutePath.filter(menu => menu.auth.includes(role))
  } 

  const accessMenu = MenuShow();

  return (
    <Switch>
      {accessMenu.map(item => {
        return (
          <Route path={item.path} exact component={item.component} />
        )
      })}
    </Switch>
  );
};

export default withRouter(DashboardROutes);
