import DashboardLayout from "./DashboardLayout";
import Login from "../components/Login/Login";
import { PrivateRoute } from "../components/PrivateRouter";
import { Switch, Route, Redirect } from "react-router-dom";

const Pages = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          if (!localStorage.getItem("role")) {
            return <Redirect to="/login" />;
          } else {
            return <Redirect to="/dashboard/items" />;
          }
        }}
      />
      <Route path="/login" exact component={Login} />
      <PrivateRoute path="/dashboard" component={DashboardLayout} />
    </Switch>
  );
};

export default Pages;
