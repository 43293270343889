import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import * as Urls from "../../constants/Config";

const ItemImage = ({ documentID, accessToken }) => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [documentID])

    return (
        
        <>
        {
            loading
            ? <Spin spinning={loading}/>
            : <img 
            src={`${Urls.API_URL}zoho_inventory/zoho_inventory_item_image?url=https://inventory.zoho.com.au/DocTemplates_ItemImage_Small_${documentID}.zbfs?organization_id=7001492318%26zomcsparam=d7885bd2038cd5e3e3b333b034ee84e79b46d9014fd4fca9cd6347b51268acfa071c157874725ddf90dafbc948981631a7e3aec989354f8966d2984a77ff5dfc&accessToken=${accessToken}`} 
            alt="item" />
        }
        </>
    )
}

export default ItemImage;