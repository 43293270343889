import React, { useEffect, useState } from "react";
import Table from "./Table";
import TableResponsive from "./Table-resonsive";
import { setItemsDisplay, setItemsStore } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import Utils from "../../util/Functions";

const ItemsTable = () => {
  const dispatch = useDispatch();
  const { items, loading, loadAgain, searchText, itemsDisplay } = useSelector((store) => store.store);
  const [itemsFilter, setItemFilter] = useState([]);
  useEffect(() => {
    if (loadAgain === true) {
      dispatch(setItemsStore());
    }
  }, [loadAgain]);

  useEffect(() => {
    if (items && items.length > 0) {
      if(itemsDisplay && itemsDisplay.length > 0) {
        setItemFilter(itemsDisplay)
      } else {
        setItemFilter(items)
      }
    }
  }, [items])

  useEffect(() => {
    const arr =
      searchText.length === 0
        ? items
        : Utils.filterArrayByString(items, searchText);
    setItemFilter(arr)
    dispatch(setItemsDisplay(arr))
  }, [searchText])

  return (
    <div>
      <Spin spinning={loading}>
        <Table items={items} itemsDisplay={itemsFilter}/>
        {/* Responsive list table  */}
        <TableResponsive items={items} itemsDisplay={itemsFilter}/>
      </Spin>
    </div>
  );
};

export default ItemsTable;
