import { useState, useEffect } from "react";
import { Button, Drawer } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import MenuByRoles from "./MenuByRoles";
// import QrCodeModal from "../Modal/QrCode";
import BarCode from "../Modal/BarCode";

const MenuBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [userInfo, setUser] = useState();
  const [visible, setVisible] = useState(false);
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
    setCollapsed(!collapsed);
  };

  const onClose = () => {
    setVisible(false);
  };

  const logout = () => {
    localStorage.removeItem("role");
    localStorage.removeItem("user");
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  return (
    <>
      {/* Responsive menu with 900px */}
      {/* <QrCodeModal
        key="QRCODE"
        handleCancel={() => setQrCodeModalVisible(false)}
        isModalVisible={qrCodeModalVisible}
        title="SCAN QR CODE"
      /> */}
      <BarCode
        key="BARCODE"
        handleCancel={() => setQrCodeModalVisible(false)}
        isModalVisible={qrCodeModalVisible}
        title="SCAN BAR CODE"
      />
      <div className="menu-respon">
        <Button type="primary" danger onClick={() => setQrCodeModalVisible(true)}>
          SCAN BAR CODE
        </Button>
        <Button className="btn-menu-respon" type="primary" onClick={showDrawer}>
          {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </Button>
        <Drawer
          title={
            <div className="collaspe-title">
              <p>Portal</p>
            </div>
          }
          placement="left"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          {userInfo && userInfo.data && (
            <div className="infor-user">
              <h2>{userInfo.data.customer && userInfo.data.customer.name}</h2>
              <h3>{userInfo.data.email}</h3>
            </div>
          )}
          <MenuByRoles />
          <a href="/login" onClick={() => logout()}>
            <h2 className="logout-title"><LogoutOutlined/> Logout</h2>
          </a>
        </Drawer>
      </div>
    </>
  );
};

export default MenuBar;
