import React from "react";
import { Card } from "antd";

const OrderCard = ({ order }) => {
  return (
    <Card className="order_card">
      <p className="contact-person_text">{order.contactPerson}</p>
      {order && order.lineItems && order.lineItems.length > 0
        ? order.lineItems.map((item) => (
            <p className="description_text">{item.description}</p>
          ))
        : ""}
      <div className="order_card--text_group">
        <p>{order.contact}</p>
        <p className="pl-5">{order.reference}</p>
        <p>{order.orderDate}</p>
      </div>
    </Card>
  );
};

export default OrderCard;
