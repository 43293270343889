import request from "../util/Api";
import * as Urls from "../constants/Config";

export const createSaleOrder = (data, file, email, name) => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("attachment", file)
    return request({
        url: `${Urls.API_URL}zoho_inventory/zoho_inventory_create_sales_orders?email=${email}&name=${name}`,
        method: "POST",
        headers: {
            "Content-Type": `multipart/form-data;boundary=${formData._boundary}`
        },
        data: formData
    })
}
