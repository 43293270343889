import React, { useEffect, useState } from "react";
import OrderCard from "./OrderCard";

const Board = ({ orders, status }) => {
  const [filterOrders, setFilterOrder] = useState([]);
  useEffect(() => {
    function filter() {
      const arr = orders.filter((order) => order.status === status);
      setFilterOrder(arr);
    }
    filter();
  }, [orders]);
  return (
    <div className="status-board">
      {status}
      {filterOrders && filterOrders.length > 0 ? (
        filterOrders.map((order) => (
          <OrderCard key={order.number} order={order} />
        ))
      ) : (
        <p className="notification_text">No Record in {status}</p>
      )}
    </div>
  );
};

export default Board;
