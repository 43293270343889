import request from "../util/Api";
import * as Urls from "../constants/Config";

const loginService = (data) => {
  return request({
    url: `${Urls.API_URL}login`,
    method: "POST",
    data,
  });
};

export { loginService };
