import { Input, List, Tag } from "antd";
import { useState } from "react";
import {  useSelector,  useDispatch } from "react-redux";
import TableListModal from "../Modal/TableList";
import { setSearchText } from "../../store/store";

const TableField = ({ items, itemsDisplay }) => {
  const dispatch = useDispatch();
  const { accessToken, searchText } = useSelector((store) => store.store);
  const [modalItem, setModalItem] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  // search item table
  const onToggleSearch = (e) => {
    dispatch(setSearchText(e.target.value))
  };

  const onToggleShow = (item) => {
    setModalItem(item);
    setIsModalVisible(true);
  };
  return (
    // Modal content
    <>
      <div className="tablist-respon">
        <div className="container">
          <div className="sales_title table_title">
            <span>Catalogue</span>{" "}
            <Input placeholder="Search" onChange={onToggleSearch} value={searchText}/>
          </div>
        </div>
        <List
          size="small"
          pagination={{
            onChange: (page) => {},
            pageSize: 10,
          }}
          dataSource={itemsDisplay}
          renderItem={(item) => (
            <>
              <List.Item key={item.item_id} onClick={() => onToggleShow(item)}>
                <List.Item.Meta
                  title={<a href={item.href}>{item.name}</a>}
                  description={
                    <>
                      {item.sku} {" "}
                      {item.quantity && item.quantity > 0 
                      ? <Tag color="green" key={item.item_id}>In Cart</Tag> 
                      : ""}
                      
                    </>
                  }
                  
                />
              </List.Item>
            </>
          )}
        />
        <TableListModal
          items={items}
          modalItem={modalItem}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          accessToken={accessToken}
        />
      </div>
    </>
  );
};

export default TableField;
