import React from "react";
import { Modal, Button, Card, InputNumber, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setItems, setLineItems } from "../../store/store";
import _ from "lodash";
import ItemImage from "../Table/Image";

const { Meta } = Card;

const TableListModal = ({
  isModalVisible,
  setIsModalVisible,
  items,
  modalItem,
  accessToken
}) => {
  const dispatch = useDispatch();
  const { lineItems } = useSelector((store) => store.store);
  
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const addToCart = (values, record) => {
    const { quantity } = values;
    const item = _.omit(record, ["product_type"]);
    dispatch(setLineItems([...lineItems, { ...item, quantity }]));
    const updateItems = items.map((ele) => {
      if (record.item_id === ele.item_id) {
        return {
          ...ele,
          quantity: quantity || 1,
        };
      }
      return ele;
    });

    dispatch(setItems(updateItems));
    handleCancel()
  };

  const removeFromCart = (record) => {
    const item = _.omit(record, ["product_type"]);
    const arr = [...lineItems];
    lineItems.forEach((ele, index) => {
      if (ele.item_id === item.item_id) {
        arr.splice(index, 1);
      }
    });
    dispatch(setLineItems(arr));
    const updateItems = items.map((ele) => {
      if (record.item_id === ele.item_id) {
        return {
          ...ele,
          quantity: 0,
        };
      }
      return ele;
    });

    dispatch(setItems(updateItems));
  };

  return (
    <div className="modal-table">
      <Modal
        title={modalItem.name}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Card
          cover={
            <ItemImage accessToken={accessToken} documentID={modalItem.image_document_id}/>
          }
        >
          <Meta title={modalItem.sku} description={modalItem.name} />
          <p
            style={{
              color: "rgba(0, 0, 0, 0.45)",
              fontSize: 14,
              margin: "0",
              paddingTop: "10px",
            }}
          >
            Price : {modalItem && modalItem.rate && modalItem.rate.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
          <p
            style={{
              color: "rgba(0, 0, 0, 0.45)",
              fontSize: 14,
            }}
          >
            Quantity on hand : {modalItem.stock_on_hand}
          </p>
          {modalItem.quantity && modalItem.quantity > 0 ? (
            <Button
              type="primary"
              danger
              onClick={() => removeFromCart(modalItem)}
            >
              Remove from Cart
            </Button>
          ) : (
            <div
              style={{
                display: "flex",
              }}
            >
              <Form
                onFinish={(values) => addToCart(values, modalItem)}
                layout="inline"
              >
                <Form.Item
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your quantity!",
                    },
                  ]}
                >
                  <InputNumber min="1" required />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Add To Cart
                </Button>
              </Form>
            </div>
          )}
        </Card>
      </Modal>
    </div>
  );
};
export default TableListModal;
