import React from "react";
import SaleOrderForm from "./SaleOrderForm";

const SaleOrderCreate = () => {

  return (
    <div>
      <SaleOrderForm />
    </div>
  );
};

export default SaleOrderCreate;
